import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";

export const OZON_YOUTUBE: TComponent = {
  componentKey: EComponents.OZON_YOUTUBE,
  name: EComponents.OZON_YOUTUBE,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Youtube',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    youtubeId: {
      name: "Youtube Video ID",
      defaultValue: "MUdJyNMx8kc",
    },
  },
};
