import {BuilderProActions} from "@/store/builder-pro/types";
import _cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import {
  DEFAULT_EPACK_DATA,
  DEFAULT_TEMPLATE_CONFIGURATIONS,
} from "@/store/builder-pro/consts";
import {
  GROUPSEB_TEMPLATES_LIST
} from "@/store/builder-pro/consts/groupseb-templates";
import {handleAddingGroupsebTemplate} from "@/store/builder-pro/utils";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const templateActions: Partial<BuilderProActions> = {
  changeTemplate (template) {
    this.activeTemplate = template
    this.activePage = 'index.html'
    this.setActiveComponent({
      component: null
    })
  },

  async addTemplate (id, toTemplate) {
    const fromLang: string | undefined = this.activeLocale;
    let fromTemplate: string | undefined;

    if (fromLang) {
      fromTemplate = this.epackData.components[fromLang]?.['master_template'] ? 'master_template' : Object.keys(this.epackData.components[fromLang] || {})[0];
    }

    //let addedGroupsebTemplate = false;

    if (GROUPSEB_TEMPLATES_LIST.includes(toTemplate)) {
      if (handleAddingGroupsebTemplate({
        builderPro: this,
        fromTemplate,
        toTemplate
      })) {
        //addedGroupsebTemplate = true;
        this.onEpackDataUpdate({
          addToUpdated: {
            locale: fromLang,
            template: toTemplate,
          }
        })
      } else {
        throw new Error('Something went wrong');
      }
    } else {
      // the default behavior
      if (!id) {
        if (!this.epackData.components[this.activeLocale][toTemplate]) {
          const components = _cloneDeep(this.epackData.components)
          // setting the reference to the template
          switch (toTemplate) {
            case 'ozon_template':
              components[this.activeLocale][toTemplate] = DEFAULT_EPACK_DATA.components.ru.ozon_template
              break;
            case 'amazon_template':
              components[this.activeLocale][toTemplate] = DEFAULT_EPACK_DATA.components.ru.amazon_template
              break;
            default:
              components[this.activeLocale][toTemplate] =
                _cloneDeep(this.epackData.components[this.activeLocale].master_template)
          }
          // setting the clone of the components to the template
          this.epackData.components = components
          const manifest = this.epackData.manifest
          if (manifest?.data?.[this.activeLocale]?.minisite) {
            manifest.data[this.activeLocale].minisite[toTemplate] = {contentTypes: ["html", "json", "iframe"]}
          }
          this.epackData.manifest = manifest
          this.onEpackDataUpdate({
            addToUpdated: {
              locale: fromLang,
              template: toTemplate,
            }
          })
        }
      } else {
        if (fromLang && fromTemplate) {
          // creating a section using backend
          await this.createSection({
            id,
            fromLang,
            toLang: fromLang,
            fromTemplate,
            toTemplate
          })
        } else {
          throw new Error('Something went wrong');
        }
      }
    }

    this.activeComponent = null
    this.activeTemplate = toTemplate

    if (handleAddingGroupsebTemplate({
      builderPro: this,
      fromTemplate,
      toTemplate
    })) {
      this.onEpackDataUpdate({
        addToUpdated: {
          locale: fromLang,
          template: toTemplate,
        }
      })
    } else {
      throw new Error('Something went wrong');
    }
    
    if (!this.epackData.components[fromLang]?.[toTemplate]?.[this.activePage]) {
      this.activePage = Object.keys(this.epackData.components[fromLang]?.[toTemplate] || {})[0];
    }
    this.updateTemplateConfiguration({
      lang: this.activeLocale,
      template: this.activeTemplate,
    })

    // if (addedGroupsebTemplate) {
    //   this.updateUsedFonts();
    // }
  },

  deleteTemplateLocal (template) {
    const components = _cloneDeep(this.epackData.components)
    const templateConfiguration = _cloneDeep(this.epackData.templateConfiguration)
    delete components[this.activeLocale][template]
    delete templateConfiguration[this.activeLocale][template]
    this.epackData.components = components
    this.epackData.templateConfiguration = templateConfiguration
    if (this.activeTemplate === template) {
      this.activeTemplate = Object.keys(components[this.activeLocale])[0]
      this.activeComponent = null
    }
    const manifest = this.epackData.manifest
    delete manifest.data[this.activeLocale].minisite[template];
    this.epackData.manifest = manifest
    this.resetHistoryMulti({ locale: this.activeLocale, template })
    this.onEpackDataUpdate({addToUpdated: false, epackSavedInBackend: true})
  },

  async deleteTemplate({ template, epackId }) {
    return new Promise((resolve, reject) => {
      if (epackId) {
        axios
          .put(
            `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
            {
              lang: this.activeLocale,
              template: template,
              page: null,
              components: null
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              }
            }
          )
          .then((res) => {
            this.deleteTemplateLocal(template)
            resolve(res)
          })
          .catch(e => {
            console.error(e)
            reject(e)
          })
      } else {
        this.deleteTemplateLocal(template)
        resolve(true)
      }
    })
  },

  updateTemplateConfiguration ({ lang, template, templateConfiguration }) {
    if (templateConfiguration && DEFAULT_TEMPLATE_CONFIGURATIONS[template]) {
      for (let key in templateConfiguration) {
        const prop = templateConfiguration[key]
        if (typeof prop !== 'object') {
          templateConfiguration[key] = {
            ...DEFAULT_TEMPLATE_CONFIGURATIONS[template][key],
            value: prop
          }
        }
      }
      templateConfiguration = {
        ...DEFAULT_TEMPLATE_CONFIGURATIONS[template],
        ...templateConfiguration,
      }
    }

    this.epackData.templateConfiguration = {
      ...(this.epackData.templateConfiguration || {}),
      [lang]: {
        ...(this.epackData.templateConfiguration?.[lang] || {}),
        [template]: templateConfiguration
          || this.epackData.templateConfiguration?.[lang]?.[template]
          || DEFAULT_TEMPLATE_CONFIGURATIONS[template]
          || null,
      },
    }
  },

  updateTemplateConfigurationProp ({ value, key }) {
    const templateConfiguration = this.epackData.templateConfiguration[this.activeLocale][this.activeTemplate]
    if (!templateConfiguration) return
    templateConfiguration[key].value = value
    this.onEpackDataUpdate()
  },
}

export { templateActions }
