import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../../components";
import {EComponentTypes} from "../../../types";

export const FRIENDLY_COL_PICTURE: TComponent = {
  componentKey: EComponents.FRIENDLY_COL_PICTURE,
  name: EComponents.FRIENDLY_COL_PICTURE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Picture',
  noHover: true,
  noDynamic: true,
  noParent: true,
  isBaseComponent: true,
  props: {
    src: {
      fileType: "image",
      name: "Image",
      defaultValue: "/static/constructor/placeholders/440x440.jpg",
    },
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
    borderRadius: {
      name: "Border radius",
      defaultValue: "",
    },
    align: {
      name: "Align",
      defaultValue: "",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};
